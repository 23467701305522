import ApiService from "@/core/services/api.service.js";

const materialService = {
    getMaterialList() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/material")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
}

export default materialService;