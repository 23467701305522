import ApiService from "@/core/services/api.service.js";

const frameTypeService = {
    getFrameTypeList() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/frametype")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
}

export default frameTypeService;