<template>
	<div>
		<b-card no-body>
			<b-card header-tag="header">
				<template v-slot:header>
					<product-form-nav link="data"  :productDetails="productDetails" :productId="id"></product-form-nav>
				</template>
				<form class="form" novalidate="novalidate" id="product_data">
					<b-card-text>
						<b-row>
							<b-col class="d-flex pb-4">
								<h4 class="m-0 pr-2">Dimensions</h4>
								<b-badge variant="primary" class="small">In MM</b-badge>
							</b-col>
						</b-row>
						<b-row class="ml-1">
							<b-col lg="3" md="6">
								<b-form-group>
									<label>width</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-1 mr-2"><img src="/img/frame-width.svg"
												width="60" />
										</i>
										<b-form-input v-model="form.width" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Temple Length</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-1 mr-2"><img
												src="/img/temple-length.svg" width="70" />
										</i>
										<b-form-input v-model="form.temple_length" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Bridge width</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-1 mr-2"><img
												src="/img/bridge-width.svg" width="70" />
										</i>
										<b-form-input v-model="form.bridge_width" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>

							<b-col lg="3" md="6">
								<b-form-group>
									<label>Frame Height</label>
									<div class="position-relative">
										<i class="position-absolute right-0 top-0 mt-2 mr-2"><img src="/img/frameheight.svg"
												width="70" />
										</i>
										<b-form-input v-model="form.frame_height" placeholder=""></b-form-input>
									</div>
								</b-form-group>
							</b-col>
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Frame Weight</label>
									<b-form-input v-model="form.frame_weight" placeholder=""></b-form-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="d-flex pb-4">
								<h4 class="m-0 pr-2">Frame Type</h4>
							</b-col>
						</b-row>
						<b-row class="ml-1">
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Material</label>

									<b-form-select v-model="form.material" :options="framematerial"></b-form-select>
								</b-form-group>
							</b-col>
							<b-col lg="3" md="6">
								<b-form-group>
									<label>Frame Type</label>
									<b-form-select v-model="form.frame_type" :options="frametype"></b-form-select>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="text-right">
								<button class="btn btn-primary" ref="submit_data">
									<i class="las la-save"></i> Save and Continue
								</button>
							</b-col>
						</b-row>
					</b-card-text>
				</form>
			</b-card>
		</b-card>
	</div>
</template>

<script>
	import ProductFormNav from "./components/ProductFormNav.vue";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

	// FormValidation plugins
	import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
	import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
	import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
	import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
	import KTUtil from "@/assets/js/components/util";

	import ProductService from "@/core/services/api/product/product";
	import MaterialService from "@/core/services/api/product/material";
	import FrameTypeService from "@/core/services/api/product/frameType";
	export default {
		components: {
			ProductFormNav,
		},
		data() {
			return {
				id: this.$route.params.id?parseInt(this.$route.params.id):null,
				form:{
					width:"",
					temple_length:"",
					bridge_width:"",
					frame_height:"",
					frame_weight:"",
					material:0,
					frame_type:0
				},
				frametype: [],
				framematerial: [],
				productDetails:{}
			};
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Products",
					route: "/products",
				},
				{
					title: "Manage Product : Data",
				},
			]);
			const signin_form = KTUtil.getById("product_data");
			this.fv = formValidation(signin_form, {
				fields: {
					
				},
				plugins: {
					trigger: new Trigger(),
					submitButton: new SubmitButton(),
					bootstrap: new Bootstrap(),
				},
			});
			this.getMaterialList();
			this.getFrameTypeList();
			this.getProductById();
			this.fv.on("core.form.valid", () => {
				// var email = this.form.email;
				// var password = this.form.password;

				// clear existing errors
				// this.$store.dispatch(LOGOUT);

				// set spinner to submit button
				const submitButton = this.$refs["submit_data"];
				submitButton.classList.add("spinner", "spinner-light", "spinner-right","disabled");
				// dummy delay
				this.updateProduct(function(){
					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right",
						"disabled"
					);
				});
			});
				
			
		},
		methods: {
			async getMaterialList() {
				var response = await MaterialService.getMaterialList();
				 this.framematerial.push ({
					value:0,
					text:"Please select a frame material"
				});
				response.map((e)=>{
					this.framematerial.push ({
						value:e.id,
						text:e.name
					})
				});
			},
			async getFrameTypeList() {
				var response = await FrameTypeService.getFrameTypeList();
				 this.frametype.push ({
					value:0,
					text:"Please select a frame type"
				});
				response.map((e)=>{
					this.frametype.push ({
						value:e.id,
						text:e.name
					})
				});
			},
			async getProductById() {
				var response = await ProductService.getProductById(this.id);
				this.productDetails = response;
				this.form = {
					width:response.width,
					temple_length:response.temple_length,
					bridge_width:response.bridge_width,
					frame_height:response.frame_height,
					frame_weight:response.frame_weight,
					material:response.material,
					frame_type:response.frame_type
				}
			},
			updateProduct(callback) {
				var vm = this;
				ProductService.updateProduct(vm.id,vm.form).then(function (response) {
						vm.$router.push({ name: "product-form-attribute", param: { id: vm.id } })
				}).catch(function (error){
					callback();
				});
			},
		},
	};
</script>

<style></style>